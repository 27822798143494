import React from 'react';
import { graphql } from 'gatsby';
import { useIntl } from 'gatsby-plugin-react-intl';
import Layout from '../components/Layout';
import Builder from '../components/Builder';
import Seo from '../components/Seo';
import NotFound from '../components/NotFound';

const ArticleTemplate = ({ data }) => {
  const intl = useIntl();
  const { locale } = intl;

  if (
    data?.article?.content?.external === true ||
    (data?.access?.nodes?.length > 0 &&
      !data?.access?.nodes[0]?.visibility?.showMediaInternalArticles)
  ) {
    return <NotFound />;
  }

  return (
    <Layout>
      <Seo
        title={
          locale === 'en' && data?.article?.builder?.titleEn
            ? data.article.builder.titleEn
            : data?.article?.builder?.titleFr
        }
      />
      <div className="article-title">
        {locale === 'en' && data?.article?.builder?.titleEn
          ? data.article.builder.titleEn
          : data?.article?.builder?.titleFr}
      </div>
      <div
        className="article-thumbnail"
        style={{
          backgroundImage: `url(${data?.article?.builder?.thumbnail?.localFile?.url})`,
        }}
      />
      <Builder
        sections={
          locale === 'en' && data?.article?.builder?.contentEn?.length > 0
            ? data.article.builder.contentEn
            : data?.article?.builder?.contentFr
        }
      />
    </Layout>
  );
};

export default ArticleTemplate;

export const pageQuery = graphql`
  query articleById($id: String!) {
    article: wpMedia(id: { eq: $id }) {
      content: medias {
        external
        externalLink
      }
      id
      builder {
        titleFr
        titleEn
        thumbnail {
          localFile {
            url
          }
        }
        contentFr {
          text
          imagesPosition
          images {
            localFile {
              url
            }
          }
        }
        contentEn {
          text
          imagesPosition
          images {
            localFile {
              url
            }
          }
        }
      }
    }
    access: allWpElelementsAccess {
      nodes {
        visibility {
          showMediaInternalArticles
        }
      }
    }
  }
`;
